import { VerticalStack, P } from "../../Components";
function Unibet({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Oct 2021 - Ongoing</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Current assignment, information will come
      </P>
    </VerticalStack>
  );
}

export default Unibet;
