import { VerticalStack, P } from "../../Components";
function Unibet({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Aug 2010 - Jun 2015</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Civilingenjörsexamen, Elektro- och systemteknik
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Master's Degree in System Control and Robotics
      </P>
    </VerticalStack>
  );
}

export default Unibet;
