// export const orange = "#F75C03";
// export const avocado = "#697A21";
// export const saffron = "#F4C95D";
// export const jet = "#3E363F";
// export const flax = "#E7E393";
const colors = {
  lightShade: "#F8F9F8",
  lightAccent: "#9FA5AB",
  brand: "#987883",
  darkAccent: "#7A7F95",
  darkShade: "#372A48",
};
export default colors;
