import { VerticalStack, P } from "../../Components";
function Axakon({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Jun 2015 - Jul 2022</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        During my 7 years, Axakon has gone through a lot of change and phases,
        which is natural for a company growing from a handful of people to 35+.
        This is a journey that I am very thankful for having been able to impact
        and being part of. I have made a lot of friends and memories during this
        time, and even got the (un)official title as Mr Axakon from my coworkers
        ;-)
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Besides my full time consultant assignments, I have been
        part of the strategic team for many years which has built the company to
        what it is today (2022). The team has handled questions regarding salary
        models, development models, budgets, recruitment, planning conferences
        etc. I have also been fully responsible for Axakons (and a sister
        companys) IT related strategy, budget and purchases for many years.
      </P>
    </VerticalStack>
  );
}

export default Axakon;
