import { VerticalStack, P } from "../../Components";
function Encare({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Feb 2016 - Jul 2016</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        At Encare, Erik took on the responsibility of a full-stack developer,
        working independently on a notable project to develop a learning portal.
        His role involved creating an intuitive platform where educational
        programs could be assembled, materials distributed, and user engagement
        monitored, such as tracking who accessed what content and when.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Utilizing a suite of technologies including React and Node.js for the
        frontend and backend respectively, and MongoDB for data storage, Erik
        built a system that wasn't just functional but user-friendly.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        This project underscored Erik's ability to manage and execute a
        development project single-handedly, showcasing his comprehensive skill
        set and ability to deliver a complete, working solution from scratch.
        His work on the learning portal demonstrated not just his technical
        capabilities, but also his understanding of user engagement and content
        delivery in the educational space.
      </P>
    </VerticalStack>
  );
}

export default Encare;
