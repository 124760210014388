import styled from "styled-components";

const Stack = styled.div<{
  $background?: string;
  $height?: string;
  $width?: string;
  $maxWidth?: string;
  $justifyContent?: string;
  $marginTop?: string;
  $margin?: string;
  $padding?: string;
  $alignItems?: string;
  $opacity?: string;
  $position?: string;
  $overflowY?: string;
  $overflowX?: string;
  $overflow?: string;
  $blur?: string;
  $scrollSnapType?: string;
  $scrollSnapAlign?: string;
  $cursor?: string;
}>`
  ${(props) =>
    props.$blur ? "-webkit-backdrop-filter: blur(" + props.$blur + ");" : ""}
  ${(props) =>
    props.$blur ? "backdrop-filter: blur(" + props.$blur + ");" : ""}
  ${(props) =>
    props.$scrollSnapType
      ? "scroll-snap-type: " + props.$scrollSnapType + ";"
      : ""}
      ${(props) =>
    props.$scrollSnapAlign
      ? "scroll-snap-align: " + props.$scrollSnapAlign + ";"
      : ""}
  background: ${(props) => props.$background};
  background-size: cover;
  background-position: top;
  cursor: ${(props) => props.$cursor};
  overflow: ${(props) => props.$overflow};
  height: ${(props) => props.$height};
  position: ${(props) => props.$position};
  overflow-y: ${(props) => props.$overflowY};
  overflow-x: ${(props) => props.$overflowX};
  opacity: ${(props) => props.$opacity};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  margin-top: ${(props) => props.$marginTop};
  display: flex;
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
`;

export const HorizontalStack = styled(Stack)`
  flex-direction: row;
`;
export const VerticalStack = styled(Stack)`
  flex-direction: column;
`;
