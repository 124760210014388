import styled from "styled-components";
import  colors from "./colors";
export const Button = styled.button<{ $primary?: boolean }>`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.$primary ? colors.brand : colors.lightAccent)};
  color: ${(props) => (props.$primary ? "#000000" : colors.lightShade)};
  font-weight: 600;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid ${(props) => (props.$primary ? colors.brand : colors.lightAccent)};
  border-radius: 3px;
`;
export {};
