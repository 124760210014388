import cross from "../cross.svg";
import { setThemeColor } from "../utils";
import "../App.css";
import information from "./Information";
import { HorizontalStack, VerticalStack, H1, Image } from "../Components";
function AppScreen({
  setScreen,
  title,
  backgroundColor,
  textColor,
  titleColor,
  svgFilter,
}: {
  setScreen: (title: string) => void;
  title: string;
  backgroundColor: string;
  textColor: string;
  titleColor: string;
  svgFilter: string;
}) {
  const Information = information[title];
  setThemeColor(backgroundColor);
  return (
    <VerticalStack
      onClick={() => setScreen("home")}
      $blur="5px"
      $height="100vh"
      $scrollSnapAlign="center"
      $alignItems="center"
      $background="rgb(235 238 255 / 50%)"
    >
      <VerticalStack
        $height="100vh"
        $alignItems="center"
        $maxWidth="450px"
        $background={backgroundColor}
        $width="100%"
        onClick={(e) => e.stopPropagation()}
      >
        <HorizontalStack
          $marginTop="20px"
          $width="100%"
          $justifyContent="space-between"
          $alignItems="center"
        >
          <Image
            onClick={() => setScreen("home")}
            src={cross}
            $width="20px"
            $height="20px"
            $filter={svgFilter}
            $margin="0 0 0 20px"
          />
          <VerticalStack $alignItems="center">
            <H1 $color={titleColor}>{title}</H1>
          </VerticalStack>
          <VerticalStack $width="20px" $height="20px" $margin="0 20px 0 0" />
        </HorizontalStack>
        <VerticalStack $padding="50px" $overflowY="scroll">
          <Information textColor={textColor} />
        </VerticalStack>
      </VerticalStack>
    </VerticalStack>
  );
}

export default AppScreen;
