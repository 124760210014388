import { VerticalStack, P } from "../../Components";
function Assistansbolaget({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Apr 2019 - Sep 2019</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        At Assistansbolaget, Erik played a supportive yet crucial role in
        full-stack development, primarily assisting with enhancements to the
        company's key automotive assistance app. His contributions helped
        improve the app's performance, making it an even more reliable resource
        for users in need of towing services.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Erik also lent his skills to the B2B web frontend, focusing on fleet
        management. His work helped streamline operations, enabling a more
        efficient connection between client distress calls and the swift
        deployment of service.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Furthermore, he assisted in the development of the Android Java app
        utilized by tow truck operators, an essential tool in managing real-time
        requests and ensuring prompt response. This practical application proved
        vital in maintaining the company's reputation for quick, reliable
        service.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        On the backend, Erik provided valuable support within the PHP
        environment, aiding in fortifying the system's overall efficiency and
        robustness. His flexible involvement across different areas of the
        project not only demonstrated his adaptability but also had a tangible
        impact on the company's service quality.
      </P>
    </VerticalStack>
  );
}

export default Assistansbolaget;
