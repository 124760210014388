import "../App.css";
import omateq from "../omateq.svg";
import axakon from "../axakon.jpeg";
import klarna from "../klarna.png";
import unibet from "../unibet.png";
import easypark from "../easypark.png";
import assistansbolaget from "../assistansbolaget.png";
import encare from "../encare.png";
import delaval from "../delaval.png";
import kth from "../kth.png";
import circleChecked from "../circle-checked.svg";
import circleOutlined from "../circle-outline.svg";
import { setThemeColor } from "../utils";
import {
  HorizontalStack,
  VerticalStack,
  H3,
  H2,
  P,
  Icon,
  Widget2x2,
  Widget2x4,
  Image,
} from "../Components";
import { getWidth } from "../utils";
import { useState } from "react";
function HomeScreen({ setScreen }: { setScreen: (title: string) => void }) {
  setThemeColor("rgb(190 192 172)");
  const [sent, setSent] = useState(false);
  const sendMessage = () => {
    if (!sent) {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        window.open(
          "sms://+46722339291/?body=Hej Erik! När passar det dig att ta en fika, eller kanske en 🍔-lunch?"
        );
      } else {
        window.location.href =
          "mailto:mail@erikhallqvist.se/?subject=Ta en lunch eller fika&body=Hej Erik! När passar det dig att ta en fika, eller kanske en 🍔-lunch?";
      }
    }

    setSent(!sent);
  };
  return (
    <VerticalStack
      $height="100vh"
      $blur="5px"
      $scrollSnapAlign="center"
      $alignItems="center"
      $background="rgb(235 238 255 / 50%)"
    >
      <VerticalStack
        $height="100vh"
        $padding={(getWidth() * 3) / 100 + "px"}
        $maxWidth="450px"
        $alignItems="center"
      >
        <HorizontalStack>
          <Widget2x4 label="Information">
            <VerticalStack $padding="15px">
              <H3 $margin="0 0 7px 0" $color="#ec9251">
                Information
              </H3>
              <P>
                I'm actively shaping OMATEQ alongside friends, also doubling as
                a full-stack developer with a frontend emphasis. Visit OMATEQ's
                website or delve into my professional journey below. Scroll back
                up for contact details—I'd love to connect over fika or lunch!
                I'm keen on expanding our team and securing new projects for our
                consultants.
              </P>
            </VerticalStack>
          </Widget2x4>
        </HorizontalStack>
        <HorizontalStack>
          <Widget2x2
            onClick={() => window.open("https://www.omateq.com")}
            label="Visit my company!"
            background={`url(${omateq})`}
          ></Widget2x2>
          <VerticalStack>
            <HorizontalStack>
              <Icon
                label="Klarna"
                background={`url(${klarna})`}
                onClick={() => {
                  setScreen("Klarna");
                }}
              />
              <Icon
                label="Unibet"
                background={`url(${unibet})`}
                onClick={() => {
                  setScreen("Unibet");
                }}
              />
            </HorizontalStack>
            <HorizontalStack>
              <Icon
                label="EasyPark"
                background={`url(${easypark})`}
                onClick={() => {
                  setScreen("EasyPark");
                }}
              />
              <Icon
                label="Assistansbolaget"
                background={`url(${assistansbolaget})`}
                onClick={() => {
                  setScreen("Assistansbolaget");
                }}
              />
            </HorizontalStack>
          </VerticalStack>
        </HorizontalStack>
        <HorizontalStack>
          <VerticalStack>
            <HorizontalStack>
              <Icon
                label="Axakon"
                background={`url(${axakon})`}
                onClick={() => {
                  setScreen("Axakon");
                }}
              />

              <Icon
                label="Encare"
                background={`url(${encare})`}
                onClick={() => {
                  setScreen("Encare");
                }}
              />
            </HorizontalStack>
            <HorizontalStack>
              <Icon
                label="DeLaval"
                background={`url(${delaval})`}
                onClick={() => {
                  setScreen("DeLaval");
                }}
              />
              <Icon
                label="KTH"
                background={`url(${kth})`}
                onClick={() => {
                  setScreen("KTH");
                }}
              />
            </HorizontalStack>
          </VerticalStack>
          <Widget2x2 label="Reminders">
            <VerticalStack $padding="15px" $width="100%">
              <HorizontalStack $width="100%" $justifyContent="space-between">
                <H3 $margin="0 0 7px 0" $color="#ec9251">
                  Reminders
                </H3>
                <H2 $margin="0 0 7px 0">{sent ? 0 : 1}</H2>
              </HorizontalStack>
              <VerticalStack $alignItems="center" $marginTop="10px">
                <HorizontalStack
                  $width="100%"
                  onClick={() => sendMessage()}
                  $opacity="0.8"
                >
                  <Image
                    src={sent ? circleChecked : circleOutlined}
                    width="25px"
                    height="25px"
                    $margin="0 10px 0 0"
                  />
                  <H3>Book a fika</H3>
                </HorizontalStack>
              </VerticalStack>
            </VerticalStack>
          </Widget2x2>
        </HorizontalStack>
      </VerticalStack>
    </VerticalStack>
  );
}

export default HomeScreen;
