import { useState } from "react";
import AppScreen from "./Screens/AppScreen";
import LockScreen from "./Screens/LockScreen";
import HomeScreen from "./Screens/HomeScreen";
import "./App.css";
import { VerticalStack } from "./Components";
function App() {
  const [screen, setScreen] = useState("home");
  let mainScreen = <HomeScreen setScreen={setScreen} />;
  const appColors: Record<string, Record<string, string>> = {
    background: {
      Klarna: "rgb(255 179 199)",
      Unibet: "rgb(61 172 54)",
      EasyPark: "rgb(255 126 174)",
      Assistansbolaget: "rgb(0 0 0)",
      Axakon: "rgb(202 67 116)",
      Encare: "rgb(255 255 255)",
      DeLaval: "rgb(255 255 255)",
      KTH: "rgb(16 80 168)",
    },
    svgFilter: {
      Klarna: "invert(0)",
      Unibet: "invert(1)",
      EasyPark: "invert(0)",
      Assistansbolaget: "invert(1)",
      Axakon: "invert(1)",
      Encare: "invert(0)",
      DeLaval: "invert(0)",
      KTH: "invert(1)",
    },
    title: {
      Klarna: "rgb(0 0 0)",
      Unibet: "rgb(255 255 255)",
      EasyPark: "#56014d",
      Assistansbolaget: "rgb(255 255 255)",
      Axakon: "rgb(255 255 255)",
      Encare: "#04437b",
      DeLaval: "#1d436f",
      KTH: "rgb(255 255 255)",
    },
    text: {
      Klarna: "rgb(0 0 0)",
      Unibet: "rgb(255 255 255)",
      EasyPark: "rgb(0 0 0)",
      Assistansbolaget: "rgb(255 255 255)",
      Axakon: "rgb(255 255 255)",
      Encare: "rgb(0 0 0)",
      DeLaval: "rgb(0 0 0)",
      KTH: "rgb(255 255 255)",
    },
  };
  if (screen !== "home") {
    mainScreen = (
      <AppScreen
        backgroundColor={appColors.background[screen]}
        textColor={appColors.text[screen]}
        titleColor={appColors.title[screen]}
        svgFilter={appColors.svgFilter[screen]}
        title={screen}
        setScreen={setScreen}
      />
    );
  }
  return (
    <VerticalStack
      $width="100vw"
      $height="100vh"
      $overflowY={screen === "home" ? "scroll" : "hidden"}
      $overflowX="hidden"
      $scrollSnapType="y mandatory"
    >
      <LockScreen />
      {mainScreen}
    </VerticalStack>
  );
}

export default App;
