import { ReactElement } from "react";
import Klarna from "./Klarna";
import Unibet from "./Unibet";
import Axakon from "./Axakon";
import Encare from "./Encare";
import EasyPark from "./EasyPark";
import DeLaval from "./DeLaval";
import Assistansbolaget from "./Assistansbolaget";
import KTH from "./KTH";
const Information: Record<
  string,
  ({ textColor }: { textColor: string }) => ReactElement
> = {
  Klarna,
  Unibet,
  Axakon,
  Encare,
  EasyPark,
  DeLaval,
  Assistansbolaget,
  KTH,
};

export default Information;
