import styled from "styled-components";
import { ReactElement } from "react";
import { VerticalStack } from "./Stack";

export const WidgetBox = styled.div<{
  $height: string;
  $width: string;
  $background?: string;
}>`
  background: ${(props) => (props.$background ? props.$background : "#fff")};
  background-size: cover;
  background-position: top;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  display: flex;
  border-radius: 15px;
`;

export const WidgetText = styled.p<{}>`
  font-family: Roboto;
  font-size: var(--textHeight);
  line-height: var(--textHeight);
  font-weight: 300;
  text-shadow: 1px 1px #999;
  color: #fff;
  margin: 0;
  margin-top: var(--textPadding);
`;

export const Widget2x2 = ({
  label,
  children,
  onClick,
  background,
}: {
  label: string;
  background?: string;
  children?: ReactElement;
  onClick?: () => void;
}) => (
  <VerticalStack
    onClick={onClick}
    $cursor={onClick ? "pointer" : undefined}
    $alignItems="center"
    $width="--width2x2"
    $height="--height2"
    $margin={`var(--defaultPaddingY) var(--defaultPaddingX) ${0}px var(--defaultPaddingX)`}
  >
    <WidgetBox
      $background={background}
      $height="var(--width2x2)"
      $width="var(--width2x2)"
    >
      {children}
    </WidgetBox>
    <WidgetText>{label}</WidgetText>
  </VerticalStack>
);

export const Icon = ({
  label,
  background,
  children,
  onClick,
}: {
  label: string;
  children?: ReactElement;
  background?: string;
  onClick?: () => void;
}) => (
  <VerticalStack
    onClick={onClick}
    $cursor={onClick ? "pointer" : undefined}
    $alignItems="center"
    $width="var(--width1x1)"
    $height="var(--height1)"
    $margin={`var(--defaultPaddingY) var(--iconPaddingX) ${0}px var(--iconPaddingX)`}
  >
    <WidgetBox
      $height="var(--width1x1)"
      $width="var(--width1x1)"
      $background={background}
    >
      {children}
    </WidgetBox>
    <WidgetText>{label}</WidgetText>
  </VerticalStack>
);

export const Widget2x4 = ({
  label,
  children,
  onClick,
}: {
  label: string;
  children?: ReactElement;
  onClick?: () => void;
}) => (
  <VerticalStack
    onClick={onClick}
    $cursor={onClick ? "pointer" : undefined}
    $alignItems="center"
    $width="var(--width2x4)"
    $height="var(--height2)"
    $margin={`var(--defaultPaddingY) var(--defaultPaddingX) ${0}px var(--defaultPaddingX)`}
  >
    <WidgetBox $height="var(--width2x2)" $width="var(--width2x4)">
      {children}
    </WidgetBox>
    <WidgetText>{label}</WidgetText>
  </VerticalStack>
);
