import { VerticalStack, P } from "../../Components";
function Klarna({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Oct 2019 - Sep 2021</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        In his two years at Klarna, Erik significantly contributed to refining
        key fintech solutions, directly impacting millions of users. He was
        crucial in a team that revamped the monthly invoice system, enhancing
        the web, app aspects using React Native and backend using Node.js. This
        work didn't just boost system performance but also improved the user
        experience significantly.
      </P>

      <P $color={textColor} $margin="20px 0 0 0 ">
        Alongside this, Erik was a vital part of another team that worked on
        streamlining the checkout process for numerous online stores. This role
        deepened his understanding of the e-commerce transaction process,
        emphasizing the need for smooth user interfaces. His role went beyond
        just development, as he was instrumental in ensuring that they rolled
        out a well-tested, reliable product.
      </P>

      <P $color={textColor} $margin="20px 0 0 0 ">
        Throughout these roles, Erik honed his skills in various technologies,
        particularly React Native and Node.js, which were essential to his work.
        Moreover, he developed a strong acumen in quality assurance, using
        different tools for unit, functional, and visual testing. This
        comprehensive experience ensured he could deliver solid applications,
        strengthening his ability to produce dependable and user-friendly
        software solutions.
      </P>
    </VerticalStack>
  );
}

export default Klarna;
