import { VerticalStack, P } from "../../Components";
function DeLaval({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Aug 2015 - Feb 2016</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        At DeLaval, Erik was entrusted with a unique project as a solo
        full-stack developer, focusing on the intricate task of data integration
        from various clients. Faced with the challenge of a non-standardized
        method clients used to relay lab results, he was tasked with developing
        a scraper using C# to automate the collection of this data from
        different client websites.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Erik efficiently stored the scraped data in Elasticsearch, paving the
        way for advanced data analysis. Taking his responsibilities a step
        further, he skillfully built an internal website using React and
        Node.js, where this data was not just accessible, but also presented in
        an easy-to-understand format through various graphs. This visual
        representation of lab results offered an intuitive interface for staff,
        enhancing internal understanding and facilitating informed
        decision-making processes.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Handling both the complexities of data collection through scraping and
        the frontend presentation of the information, Erik demonstrated his
        adaptability and technical prowess. His contributions significantly
        streamlined a previously manual and time consuming process, providing
        DeLaval with an efficient, simplified solution for data retrieval and
        analysis.
      </P>
    </VerticalStack>
  );
}

export default DeLaval;
