import styled from "styled-components";
import colors from "./colors";

export const H1 = styled.h1<{
  $margin?: string;
  $color?: string;
  $textShadow?: boolean;
}>`
  ${(props) => (props.$textShadow ? "text-shadow: 1px 1px #999;" : "")}
  font-family: Roboto;
  font-weight: 700;
  font-size: 2.3em;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;

export const H2 = styled.h2<{
  $margin?: string;
  $color?: string;
  $textShadow?: boolean;
}>`
  ${(props) => (props.$textShadow ? "text-shadow: 1px 1px #999;" : "")}
  font-family: Roboto;
  font-weight: 700;
  font-size: 1.2em;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;

export const H3 = styled.h3<{
  $margin?: string;
  $color?: string;
}>`
  font-family: Roboto;
  font-weight: 700;
  font-size: 0.95em;
  line-height: 25px;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;

export const H4 = styled.h4<{
  $margin?: string;
  $color?: string;
}>`
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 1.3em;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;

export const H6 = styled.h6<{
  $margin?: string;
  $color?: string;
}>`
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 0.9em;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;

export const P = styled.p<{
  $margin?: string;
  $color?: string;
}>`
  font-family: Roboto;
  font-size: 0.85em;
  font-weight: 300;
  color: ${(props) => props.$color || colors.darkShade};
  margin: ${(props) => props.$margin || "0"};
`;
