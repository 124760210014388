import { VerticalStack, P } from "../../Components";
function EasyPark({ textColor }: { textColor: string }) {
  return (
    <VerticalStack>
      <P $color={textColor}>Aug 2016 - Mar 2019</P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        During his 2.5 years at EasyPark, Erik stepped into a key role as the
        lead frontend developer, where he spearheaded the creation of a new
        parking permit system. This innovative project was a major leap forward
        in simplifying the parking process for countless users. His leadership
        and technical skills were central to the development team's success,
        ensuring a clear direction and steadfast progress.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Additionally, Erik played a substantial role in crafting B2B systems
        that aimed to simplify operations for individuals and businesses owning
        parking lots. This work focused on easing the day-to-day management of
        parking spaces, offering a more streamlined approach that significantly
        enhanced operational efficiency for parking lot owners.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        Erik also contributed to the development of EasyPark's new public
        website. Rather than just a visual upgrade, his work was crucial in
        enhancing site functionality, improving user experience, and ensuring
        smoother user interactions.
      </P>
      <P $color={textColor} $margin="20px 0 0 0 ">
        In these projects, Erik skillfully employed a range of technologies.
        React and Node.js were his primary tools for frontend development,
        complemented by Jest for pinpoint accuracy in testing. He also
        integrated automated functional tests to ensure system reliability and
        used Redis for backend caching to boost performance. Each of these
        technical choices underscored his commitment to developing robust,
        user-centric digital solutions.
      </P>
    </VerticalStack>
  );
}

export default EasyPark;
