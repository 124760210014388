import linkedin from "../linkedin.svg";
import gmail from "../gmail.svg";
import vcard from "../vcard.png";
import upArrow from "../up-arrow.svg";
import phone from "../phone.png";

import "../App.css";
import { HorizontalStack, VerticalStack, H1, H2, Image } from "../Components";
function LockScreen() {
  return (
    <VerticalStack
      $height="100vh"
      $scrollSnapAlign="center"
      $background="rgb(235 238 255 / 50%)"
    >
      <VerticalStack
        $height="100vh"
        $alignItems="center"
        $justifyContent="space-between"
      >
        <VerticalStack $marginTop="50px" $alignItems="center">
          <H1 $color="white" $textShadow>
            Erik Hallqvist
          </H1>
          <H2 $color="white" $textShadow>Business Unit Lead / Fullstack Developer</H2>
        </VerticalStack>
        <VerticalStack $width="100vw" $maxWidth="700px">
          <VerticalStack $alignItems="center">
            <Image
              src={upArrow}
              $width="50px"
              $height="50px"
              $filter="invert(1)"
              $animationDelay={10}
            />
            <H2 $color="white">Swipe or scroll up!</H2>
          </VerticalStack>
          <HorizontalStack
            $margin="20px 0 65px 0"
            $justifyContent="space-between"
            $padding="10%"
          >
            <Image
              src={linkedin}
              $width="50px"
              $height="50px"
              $cursor="pointer"
              $animationDelay={33}
              onClick={() =>
                window.open("https://www.linkedin.com/in/erikhallqvist/")
              }
            />
            <Image
              src={gmail}
              $width="50px"
              $height="50px"
              $cursor="pointer"
              $animationDelay={36}
              onClick={() =>
                (window.location.href = "mailto:mail@erikhallqvist.se")
              }
            />
            <Image
              src={phone}
              $width="50px"
              $height="50px"
              $cursor="pointer"
              $animationDelay={39}
              onClick={() => (window.location.href = "tel:+46722339291")}
            />
            <Image
              src={vcard}
              $width="50px"
              $height="50px"
              $cursor="pointer"
              $animationDelay={42}
              onClick={() => (window.location.href = "/erikhallqvist.vcf")}
            />
          </HorizontalStack>
        </VerticalStack>
      </VerticalStack>
    </VerticalStack>
  );
}

export default LockScreen;
