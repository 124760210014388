import styled from "styled-components";
import colors from "./colors";
import { ReactElement } from "react";
import { VerticalStack } from "./Stack";
export const Card = styled.div<{
  $background?: string;
  $height?: string;
  $width?: string;
  $margin?: string;
  $marginTop?: string;
  $marginBottom?: string;
}>`
  background: ${(props) => props.$background || colors.lightShade};
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  margin: ${(props) => props.$margin};
  display: flex;
  max-width: 500px;
  border-radius: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-top: ${(props) => props.$marginTop};
  margin-top: ${(props) => props.$marginBottom};
  padding: 35px;
`;

export const Image = styled.img<{
  $width?: string;
  $height?: string;
  $maxHeight?: string;
  $margin?: string;
  $opacity?: string;
  $borderRadius?: string;
  $cursor?: string;
  $shadow?: boolean;
  $animationDelay?: number;
  $filter?: string;
}>`
  filter: ${(props) => props.$filter};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  max-height: ${(props) => props.$maxHeight};
  margin: ${(props) => props.$margin};
  opacity: ${(props) => props.$opacity};
  cursor: ${(props) => props.$cursor};
  border-radius: ${(props) => props.$borderRadius || (props.$shadow && "4%")};
  transition: all 0.3s ease-in-out 0s;
  animation: fly-ball-${({ $animationDelay = 0 }) => $animationDelay.toString()}
    5s infinite;
  ${(props) => !props.$animationDelay && "animation: none;"};
  @keyframes fly-ball-${({ $animationDelay = 0 }) =>
    $animationDelay.toString()} {
    0% {
      transform: translateY(0rem);
    }
    ${({ $animationDelay = 0 }) => ($animationDelay + 0.1).toString()}% {
      transform: translateY(0rem);
    }
    ${({ $animationDelay = 0 }) => ($animationDelay + 5).toString()}% {
      transform: translateY(-0.575rem);
    }
    ${({ $animationDelay = 0 }) => ($animationDelay + 10).toString()}% {
      transform: translateY(0rem);
    }
  }
  ${(props) =>
    props.$cursor && " &:hover { transform: translateY(-0.575rem); }"}
  ${(props) =>
    props.$shadow &&
    "box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);"};
`;

export const ImageCard = ({
  image,
  imageBorderRadius,
  children,
  onClick,
}: {
  image: string;
  imageBorderRadius?: string;
  children: ReactElement;
  onClick?: () => void;
}) => (
  <Card $marginTop="80px">
    <VerticalStack $width="100%">
      <Image
        $margin="-80px 0 0"
        $cursor={onClick && "pointer"}
        $borderRadius={imageBorderRadius}
        $shadow
        src={image}
        onClick={onClick}
      />
      {children}
    </VerticalStack>
  </Card>
);
